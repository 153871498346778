import { PageProps } from 'gatsby';
import { ActionData } from 'packages/innedit';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import Action from '~/components/List/Item/Action';
import CMSView from '~/components/View';
import List from '~/containers/Espace/List';
import params from '~/params/action.json';
import TemplateEspace from '~/templates/espace';
import requireEspace, { EspaceProps } from '~/utils/requireEspace';
import { UserProps } from '~/utils/requireUser';
import useActions from '~/utils/useActions';

const PageAutomatisationActions: FC<
  PageProps & EspaceProps & UserProps & { pageContext: { tab?: number } }
> = ({ espace, location, user, pageContext: { tab } }) => {
  const { t } = useTranslation();
  const items = useActions({
    espaceId: espace.id,
    pathname: location.pathname,
  });

  return (
    <TemplateEspace espace={espace} user={user}>
      <CMSView>
        <List
          itemList={Action}
          model={
            new ActionData({
              params,
              espaceId: espace.id,
              orderDirection: 'desc',
              orderField: 'createdAt',
            })
          }
          search={location.search}
          subMenu={items}
          tabIndex={tab}
          title={t('actions.title')}
          user={user}
        />
      </CMSView>
    </TemplateEspace>
  );
};

export default requireEspace(PageAutomatisationActions);

import { ActionType, DocumentType } from '@innedit/innedit-type';
import { ActionData } from 'packages/innedit';
import React, { FC, useEffect, useState } from 'react';

import { ListItemProps } from '~/containers/Espace/List/props';

import ListItem from './index';

const ListItemAction: FC<ListItemProps<ActionType, ActionData>> = ({
  docId,
  index,
  onClick,
  model,
}) => {
  const [doc, setDoc] = useState<DocumentType<ActionType>>();
  // const [template, setTemplate] = useState<DocumentType<TemplateType>>();

  useEffect(() => {
    let isMounted = true;
    const unsub = model.watchById(docId, document => {
      if (isMounted) {
        setDoc(document);
        // if (document?.templateId) {
        //   const templateData = new TemplateData({ espaceId: model.espaceId });
        //   templateData
        //     .findById(document.templateId)
        //     .then(snapshot => {
        //       if (isMounted) {
        //         setTemplate(snapshot);
        //       }
        //
        //       return isMounted;
        //     })
        //     .catch(error => toast.error(error.message));
        // }
      }
    });

    return () => {
      isMounted = false;
      if (unsub) {
        unsub();
      }
    };
  }, [docId, model]);

  if (!doc) {
    return null;
  }

  return (
    <ListItem
      description={doc.description}
      displayActionId={false}
      doc={doc}
      index={index}
      label={`${doc.trigger} ⇾ ${doc.collection}`}
      onClick={onClick}
      subLabel={doc.type}
    />
  );
};

export default ListItemAction;
